// 搜索表单
export const columns = [
  {
    title: '工单编号',
    dataIndex: 'workNumber',
    key: 'workNumber',
    fixed: 'left',
    width: '150px',
    customRender: (value, row, index) => {
      let str = '-';
      if (value) {
        str = value;
      }
      return str;
    },
  },
  { title: '工单状态', dataIndex: 'workStateName', key: 'workStateName', fixed: 'left', width: '100px' },
  { title: '最后修改时间', dataIndex: 'gmtModified', key: 'gmtModified', fixed: 'left', width: '150px' },
  { title: '需求描述', dataIndex: 'demandDescribe', key: 'demandDescribe', width: '500px',  scopedSlots: { customRender: "demandDescribe" }, },
  {
    title: '需求来源',
    dataIndex: 'demandSource',
    key: 'demandSource',
    customRender: (value, row, index) => {
      switch (value) {
        case 1:
          return 'i站工单';
        case 2:
          return '用户';
        case 3:
          return '400热线';
        case 4:
          return 'i友圈';
        case 5:
          return '孵化器工单';
      }
    },
  },
  {
    title: '需求类型',
    dataIndex: 'demandTypeName',
    key: 'demandTypeName',
    customRender: (value, row, index) => {
      let str = '-';
      if (value) {
        str = value;
      }
      return str;
    },
  },
  { title: '需求提出时间', dataIndex: 'startingTime', key: 'startingTime', width: '150px' },
  {
    title: '项目信息', dataIndex: 'enterpriseProjectName', key: 'enterpriseProjectName',
    customRender: (value, row, index) => {
      let str = '-';
      if (value) {
        str = value;
      }
      return str;
    },
  },
  {
    title: '企业信息', dataIndex: 'enterpriseName', key: 'enterpriseName', width: '220px',
    customRender: (value, row, index) => {
      let str = '-';
      if (value) {
        str = value;
      }
      return str;
    },
  },
  {
    title: '需求发起人',
    dataIndex: 'createName',
    key: 'createName',
    customRender: (value, row, index) => {
      let str = '-';
      if (value) {
        str = value;
      }
      return str;
    },
  },
  {
    title: '服务负责人',
    dataIndex: 'principalName',
    key: 'principalName',
    customRender: (value, row, index) => {
      let str = '-';
      if (value) {
        str = value;
      }
      return str;
    },
  },
  {
    title: '服务协作人',
    dataIndex: 'cooperationName',
    key: 'cooperationName',
    customRender: (value, row, index) => {
      let str = '-';
      if (value) {
        str = value;
      }
      return str;
    },
  },
  {
    title: '需求一级分类',
    dataIndex: 'firstsClassifyName',
    key: 'firstsClassifyName',
    customRender: (value, row, index) => {
      let str = '-';
      if (value) {
        str = value;
      }
      return str;
    },
  },
  {
    title: '需求二级分类',
    dataIndex: 'secondsClassifyName',
    key: 'secondsClassifyName',
    customRender: (value, row, index) => {
      let str = '-';
      if (value) {
        str = value;
      }
      return str;
    },
  },
  {
    title: '需求三级分类',
    dataIndex: 'thirdsClassifyName',
    key: 'thirdsClassifyName',
    customRender: (value, row, index) => {
      let str = '-';
      if (value) {
        str = value;
      }
      return str;
    },
  },
  {
    title: '需求服务类型',
    dataIndex: 'demandServerTypeName',
    key: 'demandServerTypeName',
    customRender: (value, row, index) => {
      let str = '-';
      if (value) {
        str = value;
      }
      return str;
    },
  },
  {
    title: '是否有效需求',
    dataIndex: 'isReasonableDemand',
    key: 'isReasonableDemand',
    customRender: (value, row, index) => {
      return value == 1 ? '是' : value == 0 ? '否' : '-';
    },
  },
  {
    title: '实际解决情况',
    dataIndex: 'solutionInformation',
    key: 'solutionInformation',
    customRender: (value, row, index) => {
      return value == 1 ? '实际解决' : value == 2 ? '及时回复、响应' : value == 3 ? '受限' : '-';
    },
  },
  {
    title: '回访方式',
    dataIndex: 'returnVisitType',
    key: 'returnVisitType',
    customRender: (value, row, index) => {
      return value == 1 ? '满意度调查' : value == 2 ? '电话回访' : value == 3 ? '不回访' : '-';
    },
  },
  {
    title: '需求是否解决',
    dataIndex: 'isResolved',
    key: 'isResolved',
    width: '150px',
    customRender: (value, row, index) => {
      return value == 1 ? '是' : value == 0 ? '否' : '-';
    },
  },
  {
    title: '服务满意度',
    dataIndex: 'isSatisfaction',
    key: 'isSatisfaction',
    customRender: (value, row, index) => {
      return value == 1 ? '满意' : value == 2 ? '一般' : value == 3 ? '不满意' : '-';
    },
  },
  {
    title: '操作',
    fixed: 'right',
    key: 'action',
    width: '300px',
    scopedSlots: { customRender: 'action' },
  },
];

export const searchForm = {
  workNumber: '', //工单编号
  demandSourceList: [], //需求来源(1:内部 2:用户)
  demandTypeList: [], //需求类型
  enterpriseIdList: [], //企业id集合
  projectIdList: [], //项目id集合
  workStateList: [], //工单状态
  startTime: '', //需求提出开始时间
  endTime: '', //需求提出结束时间
  createIdList: [], //需求发起者集合
  principalIdList: [], //服务负责人集合
  cooperationIdList: [], //服务协作人集合
  classifyList: [], //需求分类集合
  demandServerTypeList: [], //需求服务类型集合
  isReasonableDemandList: [], //是否为有效需求集合(1:是 0:否)
  solutionInformationList: [], //实际解决情况集合( 1:实际解决 2:及时回复、响应 3:受限）
  returnVisitTypeList: [], //回访方式集合(1:满意度调查 2:电话回访)
  isSatisfactionList: [], //是否满意集合(1:满意 2:一般 3:不满意)
  isPromptResponseList: [], //是否响应及时(1:是 0 否)
  workOrderIds: [], //工单ID集合
};

export const dataList = {
  workerStatusList: [], //工单状态
  projectMsgList: [], //项目信息
  enterpriseMsg: [], //企业信息
  demandSourceList: [
    { label: 'i站工单', value: 1 },
    { label: '用户', value: 2 },
    { label: '400热线', value: 3 },
    { label: 'i友圈', value: 4 },
    { label: '孵化器工单', value: 5 },
  ], //需求来源
  demandTypeList: [], //需求类型
  demandInitiateList: [], //需求发起者
  serviceHeadList: [], //服务负责人
  serviceCollaborateList: [], //服务协作人
  demandClassifyList: [], //需求分类
  demandServiceTypeList: [], //需求服务类型
  validOrNotDemand: [
    { label: '是', value: 1 },
    { label: '否', value: 0 },
  ], //是否有效需求
  realitySituationList: [
    { label: '实际解决', value: 1 },
    { label: '及时回复、响应', value: 2 },
    { label: '受限', value: 3 },
  ], //实际解决情况
  followUpTypeList: [
    { label: '满意度调查', value: 1 },
    { label: '电话回访', value: 2 },
    { label: '不回访', value: 3 },
  ], //回访方式
  serviceSatisfactionList: [
    { label: '满意', value: 1 },
    { label: '一般', value: 2 },
    { label: '不满意', value: 3 },
  ], //服务满意度
  replytimelyList: [
    { label: '是', value: 1 },
    { label: '否', value: 0 },
  ], //响应是否及时
};

// 对换行数据处理
export function handleData(value) {
  if (value) {
    return '<p>' + value.replace(/ /g, '&nbsp;').replace(/\n|\r\n/g, '</p><p>') + '<p>';
  } else {
    return '';
  }
}